import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, CardContent, Typography, Card, Grid } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { setLoading } from "../../../ducks/loading";
import { dataFrequencyFormat, getNumberFormat } from "../../../helpers";
import { formattedValue } from "../MyPerformance";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        marginTop: 8,
        marginBottom: 35,
    },
    heading: {
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginBottom: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
    },
    season_Title: {
        '& h4': {
            color: '#000000a6',
            margin: 0,
        }
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    card: {
        padding: '10px 8px',
        '& .card': {
            height: 135,
            background: '#FFFFFF',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 4,
            padding: '0px 5px',
        },
        '& .cardTitle': {
            fontSize: 14,
            lineHeight: '20px',
            color: 'rgba(0, 0, 0, 0.9)',
            margin: '6px 0px',
            fontWeight: 600,

        },
        '& .cardContent': {
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 4,
            padding: '4px 8px',
            '& h1': {
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 24,
                margin: 0,
                fontWeight: 500,
                lineHeight: '42px'
            }
        },
        '& .perform': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            '& span': {
                fontSize: 11,
                color: 'rgba(0, 0, 0, 0.5)',
            },
            '& p': {
                margin: 0,
                fontSize: 13,
                color: 'rgba(0, 0, 0, 0.9)',
            },
            '& .values': {
                textAlign: 'center'
            },
            '& .divider': {
                margin: '0px 2px'
            }
        }
    },
    cardHeader: {
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .titleHdr': {
            display: 'flex',
            alignItems: 'center',
            '& .rank': {
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 4,
                textAlign: 'center',
                padding: '2px 5px',
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 13,
                marginRight: 12,
                '& span': {
                    marginLeft: '-3px'
                }
            },
            '& p': {
                margin: 0
            }
        },
        '& .ColorCode': {
            margin: 0,
            width: 18,
            height: 18,
            borderRadius: 2,
        }
    },
    skeletonRoot: {
        padding: '10px 0px',
        height: 135,
        marginBottom: 150,
        "& .card": {
            bordeRadius: 8,
            padding: '10px 8px',
            borderRadius: 4,
        },
    },
}));

const OrganizationalCard = ({ filtersData, org, userDetails, orgFilterViewBy, seasonDetails }) => {
    const classes = useStyles();

    return (
        <Grid item lg={3} md={6} sm={6} xs={12}>
            <Card className='card' variant="outlined">
                <div className={classes.cardHeader}>
                    <div className="titleHdr">
                        <Typography className='cardTitle'> {org.kpi_name}</Typography>
                    </div>
                </div>
                <CardContent className="cardContent">
                    <h1>{formattedValue(userDetails, (org?.[dataFrequencyFormat[orgFilterViewBy?._id]?.actual?.value] || org?.actual), org)}</h1>
                    <div className="perform">
                        {seasonDetails?.data_upload_type === 'MTD' && orgFilterViewBy?.id !== 'SEASON' ? null : (
                            <>
                                <div className="values">
                                    <span>Target</span>
                                    <p>{formattedValue(userDetails, org.target, org)}</p>
                                </div>
                                <Divider orientation="vertical" className="divider" flexItem />
                                <div className="values">
                                    <span>Ach%</span>
                                    <p>{`${getNumberFormat(userDetails?.countryCode, org?.achievement)}%`}</p>
                                </div>
                            </>
                        )}
                        {orgFilterViewBy?.id !== 'SEASON' &&
                            <>
                                {seasonDetails?.data_upload_type === 'MTD' && orgFilterViewBy?.id !== 'SEASON' ? null : <Divider orientation="vertical" className="divider" flexItem />}
                                <div className="values">
                                    <span>
                                        {orgFilterViewBy?.id === 'SEASON' ?
                                            org.frequency === 'DAILY' ? (dataFrequencyFormat[orgFilterViewBy?._id]?.target?.display || 'MTD Target') : 'WTD Target'
                                            : dataFrequencyFormat[orgFilterViewBy?._id]?.target?.display
                                        }
                                    </span>
                                    <p>{formattedValue(userDetails, (org?.[dataFrequencyFormat[orgFilterViewBy?._id]?.target?.value] || org?.planned_target), org)} </p>
                                </div>
                                <Divider orientation="vertical" className="divider" flexItem />
                                <div className="values">
                                    <span>
                                        {orgFilterViewBy?.id === 'SEASON' ?
                                            org.frequency === 'DAILY' ? (dataFrequencyFormat[orgFilterViewBy?._id]?.achievement?.display || 'MTD Ach%') : 'WTD Ach%'
                                            : dataFrequencyFormat[orgFilterViewBy?._id]?.achievement?.display
                                        }
                                    </span>
                                    <p>{`${getNumberFormat(userDetails?.countryCode, (org?.[dataFrequencyFormat[orgFilterViewBy?._id]?.achievement?.value] || org?.planned_achievement))}%`}</p>
                                </div>
                            </>
                        }
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails,
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationalCard));