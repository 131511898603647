import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, Tab, Button, CardHeader, CardContent, Typography, Card, Grid, IconButton, AccordionSummary, AccordionDetails, Tabs, Accordion } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";
import moment from 'moment';
import { setLoading } from "../../../ducks/loading";
import { getDivisionsList, getMatchesList, getOrganisationStats, getRewardStandings, getRewardsList } from "../../../ducks/game";
import { getEllipsisText, getSearchValue, setSearchQuery } from "../../../helpers";
import MyPerformanceGroups from "./myPerformanceGroups";
import MyPerformance from "./myPerformance";
import MyReporteesPerformance from "./MyReporteesPerformance";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MyTeamPlayersPerformanceHealth from "./MyTeamPlayersPerformanceHealth";
import RewardsBannerComponent from "../RewardsBanner";
import CustomDialogComponent from "../../ChaseMeet/CustomDialogComponent";
import { openCustomDialog } from "../../../ducks/meetings";
import { RewardsDetailsDub } from "../RewardsDetailsDub";
import defaultBanner from '../../../assets/images/default-banner.png';
import MyTeamPerformance from "./TeamPerformanceDashboard/myTeamPerformance";
import MyTeamsPerformanceHealth from "./TeamPerformanceDashboard/MyTeamsPerformanceHealth";
import MyteamPerformanceGroups from "./TeamPerformanceDashboard/myteamPerformanceGroups";
import MyTeamsPerformance from "./TeamPerformanceDashboard/myTeamsPerformance";
import MyTeamPlayerPerformance from "./TeamPerformanceDashboard/myTeamPlayerPerformance";
import RewardsDetails from "../RewardsDetails";
import MyKpiRanking from "./RankingSection/MyKpiRanking";
import MyTeamKpiRanking from "./RankingSection/MyTeamKpiRanking";
import OrganisationalPerformance from "./OrganisationalPerformance";
import qs from 'query-string';
import RankingSection from "./RankingSection";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '15px 25px 3px 25px',
        '& .MuiTabScrollButton-root': {
            width: '20px !important'
        }

    },
    dashboard: {
        // background: '#FFFFFF',
        // border: '1px solid #EDEDED',
        // boxShadow: '0px 0px 4px 1px rgba(162, 162, 162, 0.25)',
        borderRadius: 4,
        padding: '10px 20px',
        // margin: '15px 25px 30px 25px'
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0px',
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginLeft: 10
        }
    },
    backButton: {
        backgroundColor: '#FFF',
        border: '1px solid #bcbcbd !important',
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        paddingBottom: 10
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 48,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
        marginBottom: 5
    },
    season_Title: {
        '& h4': {
            color: '#000000a6',
            margin: 0,
        }
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    tabsContainerStyles: {
        justifyContent: 'center'
    },
    tabsRoot: {
        width: '100%',
        maxWidth: '100%',
        zIndex: 11,
        marginBottom: 20,
        // backgroundColor: 'black'
        // margin: '25px 0px',
    },
    tabStyles: {
        minHeight: 40,
        textTransform: 'capitalize',
        minWidth: `calc(34% - 20px)`,
        padding: '6px',
        fontSize: 13,
        textAlign: 'left',
        opacity: 1,
        margin: '0 6px',
        borderRadius: 4,
        transition: 'top 0.2s ease-in-out',
        // boxShadow: '-4px 3px 10px -7px #000',//0px 1px 4px 1px rgba(161, 161, 161, 0.25)', //-4px 3px 10px -7px #000',
        // backgroundColor: '#1C0C49',
        // background: 'linear-gradient(135deg, rgba(93, 95, 239, 0.90) 0%, rgba(93, 95, 239, 0.70) 100%)',
        "& .label": {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '12px 0',
            minWidth: 260,
            width: '100%',
            "& p": {
                margin: 0,
                wordBreak: 'break-all',
                maxWidth: 220,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                "&:first-child": {
                    fontWeight: 600
                },
                "&:nth-child(2)": {
                    fontSize: 12,
                    // color: '#D6AF36',
                    fontWeight: 600,
                },
                "&:nth-child(3)": {
                    fontSize: 11,
                    marginTop: 2
                },
            }
        }
    },
    expiredLabel: {
        color: 'red',
        fontSize: 11,
        fontWeight: 600,
        borderRadius: 4,
        position: 'absolute',
        top: 7,
        left: 17,
    },
    imageStyles: {
        height: 80,
        width: 80,
        '& img': {
            objectFit: 'contain'
        }
    }
}));

const PerformanceDashboard = ({ filtersData, matches, history, divisions, userInfo, getDivisionsList, setFiltersData, getMatchesList, userHierarchieRoles, isAdmin, getRewardsList, openCustomDialog, teams, getRewardStandings, getOrganisationStats, seasonDetails, orgFilterView }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [rewards, setRewards] = useState([]);
    const [orgsStats, setOrgsStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const qryparams = qs.parse(history.location.search);


    useEffect(() => {
        let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
        if (seasonId) {
            getRewardsList({ seasonId: seasonId, params: { page: 1, limit: 5000 }, onSuccessCB, onErrorCB });
        }
    }, [filtersData?.season?._id]);

    useEffect(() => {
        let seasonId = qryparams.season ? qryparams.season : filtersData?.season?._id;
        if (seasonId) {
            getOrganisationDetails(seasonId, orgFilterView);
        }
    }, [orgFilterView])

    const onSuccessCB = (result, headers) => {
        if (result) {
            setLoading(true);
            setRewards(result?.map(reward => ({ ...reward, expired: moment(reward?.end_date).isBefore(new Date(), 'day') })));
        }
        setLoading(false);
    }
    const onErrorCB = (error) => {
        setLoading(false);
        enqueueSnackbar((error?.response && error?.response?.data) ? error?.response?.data?.error_description : 'Failed to fetch points', {
            variant: 'error',
            preventDuplicate: true
        });
    }

    const handleRewardSelect = (index) => {
        openCustomDialog({ open: true, children: <RewardsDetails reward={rewards[index]} rewardIndex={index} getRewardStandings={getRewardStandings} setLoading={setLoading} filtersData={filtersData} />, title: "Rewards", from: "standings" });
    }


    const handleGoBack = () => {
        history.push(`/leagues`);
    }

    //organisation performances
    const getOrganisationDetails = (seasonId, view) => {
        getOrganisationStats({
            seasonId,
            params: { cycle: view?.id ? (view.id === 'SEASON' ? null : view.id) : null, cycle_id: view ? (seasonDetails?.current_cycles?.[view?.id?.toLowerCase()]?._id || null) : null, limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result) {
                    const playerPoints = result[0];
                    if (playerPoints) {
                        let kpis_attributes = playerPoints?.kpis // seasonKpiPositionsSort(playerPoints?.kpis, seasonKpiPostions, 'kpi_id');
                        setOrgsStats(kpis_attributes);
                    }
                    else {
                        setOrgsStats([]);
                    }
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }


    return (
        <div className={classes.root}>
            <div className={classes.performance}>
                <div className={classes.season_head}>
                    <div className={classes.season_Title}>
                        <h4>{filtersData?.league?.league_name}</h4>
                    </div>
                    <div className={classes.season_}>
                        <button>
                            <Typography className="seasnHdng" title={filtersData?.season?.name}>
                                {filtersData?.season && getEllipsisText(filtersData?.season?.name, 12)}
                            </Typography>
                        </button>
                    </div>
                </div>
                <div className={classes.dashbrd_root}>
                    {/* <div className={classes.heading}>
                    <IconButton title="Back" className={classes.backButton} size="small" onClick={() => handleGoBack()}><ChevronLeft /></IconButton>
                    <h2>{filtersData?.league}</h2>
                </div> */}
                </div>
                <div className={classes.dashboard}>
                    <div className={classes.rewards}>
                        <div className={classes.mainRewards}>
                            {
                                rewards?.length > 0 &&
                                <>
                                    <Tabs
                                        id="filter-tabs"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="on"
                                        aria-label="scrollable auto tabs example"
                                        className={classes.tabsRoot}
                                        classes={{ flexContainer: rewards?.length < 3 && classes.tabsContainerStyles }}

                                    >
                                        {console.log('rewards list ', rewards)}
                                        {
                                            [...rewards].map((obj, i) =>
                                                (obj?.reward_status.search("Expired") === -1) ?

                                                    <Tab className={classes.tabStyles} key={i} onClick={() => handleRewardSelect(i)} label={

                                                        <div onClick={() => handleRewardSelect(i)} style={{ cursor: 'pointer' }} >
                                                            <RewardsBannerComponent obj={obj} style={{ marginLeft: 10 }} from="dashboard" />
                                                        </div>


                                                    } />
                                                    :
                                                    <Tab style={{ cursor: 'auto' }} className={classes.tabStyles} key={i} label={
                                                        <div>
                                                            <RewardsBannerComponent obj={obj} style={{ marginLeft: 10 }} from="dashboard" />
                                                        </div>
                                                    } />
                                            )
                                        }
                                    </Tabs >
                                    {rewards?.length > 0 && <CustomDialogComponent />}
                                </>
                            }
                        </div>
                    </div>
                    {
                        (filtersData.season?.season_format === 'HEAD_TO_HEAD') ?
                            <>
                                {seasonDetails?.player_role === 'VIEWER' && <OrganisationalPerformance filtersData={filtersData} isAdmin={isAdmin} orgsStats={orgsStats} loading={loading} />}
                                {seasonDetails?.player_role === 'PLAYER' && <MyPerformance filtersData={filtersData} isAdmin={isAdmin} />}
                                {['PLAYER', 'NON_PLAYER'].includes(seasonDetails?.player_role) && <OrganisationalPerformance filtersData={filtersData} isAdmin={isAdmin} orgsStats={orgsStats} loading={loading} />}
                                <RankingSection filtersData={filtersData} teams={[]} kpiRanking={"Player"} />
                            </>
                            :
                            (filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints) ?
                                <>
                                    {seasonDetails?.player_role === 'VIEWER' && <OrganisationalPerformance filtersData={filtersData} isAdmin={isAdmin} orgsStats={orgsStats} loading={loading} />}
                                    {seasonDetails?.player_role === 'PLAYER' && <MyPerformance filtersData={filtersData} isAdmin={isAdmin} />}
                                    {(userHierarchieRoles.length > 0) && <MyTeamPlayersPerformanceHealth filtersData={filtersData} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} />}
                                    {(userHierarchieRoles.length > 0) && <MyReporteesPerformance filtersData={filtersData} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} />}
                                    <MyTeamsPerformanceHealth filtersData={filtersData} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} />
                                    <MyTeamsPerformance filtersData={filtersData} divisions={divisions} matches={matches} isAdmin={isAdmin} userInfo={userInfo} />
                                    {(teams.length > 0) && <MyTeamPlayerPerformance filtersData={filtersData} teams={teams} isAdmin={isAdmin} />}
                                    {['PLAYER', 'NON_PLAYER'].includes(seasonDetails?.player_role) && <OrganisationalPerformance filtersData={filtersData} isAdmin={isAdmin} orgsStats={orgsStats} loading={loading} />}
                                    {(divisions?.length > 0) && <MyteamPerformanceGroups filtersData={filtersData} divisions={divisions} matches={matches} isAdmin={isAdmin} userInfo={userInfo} />}
                                </> :
                                filtersData?.season?.season_format === "TEAM_RACE" ?
                                    <>
                                        {seasonDetails?.player_role === 'VIEWER' && <OrganisationalPerformance filtersData={filtersData} isAdmin={isAdmin} orgsStats={orgsStats} loading={loading} />}
                                        {userInfo?.myTeams?.length === 1 && <MyTeamPerformance filtersData={filtersData} isAdmin={isAdmin} />}
                                        <MyTeamsPerformanceHealth filtersData={filtersData} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} />
                                        <MyTeamsPerformance filtersData={filtersData} divisions={divisions} matches={matches} isAdmin={isAdmin} userInfo={userInfo} />
                                        {['PLAYER', 'NON_PLAYER'].includes(seasonDetails?.player_role) && <OrganisationalPerformance filtersData={filtersData} isAdmin={isAdmin} orgsStats={orgsStats} loading={loading} />}
                                        {(divisions?.length > 0) && <MyteamPerformanceGroups filtersData={filtersData} divisions={divisions} matches={matches} isAdmin={isAdmin} userInfo={userInfo} />}
                                        <RankingSection filtersData={filtersData} teams={teams} kpiRanking={"Team"} />

                                    </> :
                                    <>
                                        {seasonDetails?.player_role === 'VIEWER' && <OrganisationalPerformance filtersData={filtersData} isAdmin={isAdmin} orgsStats={orgsStats} loading={loading} />}
                                        {seasonDetails?.player_role === 'PLAYER' && <MyPerformance filtersData={filtersData} isAdmin={isAdmin} />}
                                        {(userHierarchieRoles.length > 0) && <MyTeamPlayersPerformanceHealth filtersData={filtersData} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} />}
                                        {(userHierarchieRoles.length > 0) && <MyReporteesPerformance filtersData={filtersData} userHierarchieRoles={userHierarchieRoles} isAdmin={isAdmin} />}
                                        {['PLAYER', 'NON_PLAYER'].includes(seasonDetails?.player_role) && <OrganisationalPerformance filtersData={filtersData} isAdmin={isAdmin} orgsStats={orgsStats} loading={loading} />}
                                        {(divisions?.length > 0) && (filtersData?.season?.season_format === "PLAYER_RACE" || (filtersData.season?.season_format === 'TEAM_RACE' && filtersData.season?.auto_player_sprints)) &&
                                            <MyPerformanceGroups filtersData={filtersData} divisions={divisions} matches={matches} isAdmin={isAdmin} userInfo={userInfo} />
                                        }
                                        <RankingSection filtersData={filtersData} teams={[]} kpiRanking={"Player"} />
                                    </>
                    }
                </div >
            </div >
        </div >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonDetails: state.game.seasonDetails,
    orgFilterView: state.game.orgFilterView
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getDivisionsList: (options) => dispatch(getDivisionsList(options)),
        getMatchesList: (options) => dispatch(getMatchesList(options)),
        getRewardsList: (options) => dispatch(getRewardsList(options)),
        openCustomDialog: (options) => dispatch(openCustomDialog(options)),
        getRewardStandings: (options) => dispatch(getRewardStandings(options)),
        getOrganisationStats: (options) => dispatch(getOrganisationStats(options)),

    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PerformanceDashboard));