import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { setLoading } from "../../../ducks/loading";
import { getPlayersPerformanc, setFilterView } from "../../../ducks/game";
import MyperformanceCard from "./myperformanceCard";
import Skeleton from "@material-ui/lab/Skeleton";
import { dataFrequencyFormat, formatDate } from "../../../helpers";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        // marginTop: 8,
        marginBottom: 35,
    },
    heading: {
        '& h2': {
            color: '#363636',
            fontWeight: 600,
            lineHeight: '24px',
            fontSize: 16,
            margin: 0,
            marginBottom: 10
        }
    },
    performance: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
        // paddingBottom: 10
    },
    season_head: {
        background: '#EBEBEB',
        borderRadius: '8px 8px 0px 0px',
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px',
        // marginBottom: 5
    },
    season_Title: {
        '& h4': {
            color: '#000000a6',
            margin: 0,
        }
    },
    season_: {
        '& button': {
            background: '#00000014',
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 6px',
            color: '#000000a6',
            fontSize: 14
        },
        '& p': {
            color: '#000000a6',
            fontSize: 14
        }
    },
    card: {
        padding: '10px 8px',
        '& .card': {
            // width: 312,
            height: 135,
            background: '#FFFFFF',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 4,
            padding: '0px 5px',
        },
        '& .cardTitle': {
            fontSize: 14,
            lineHeight: '20px',
            color: 'rgba(0, 0, 0, 0.9)',
            margin: '6px 0px',
            fontWeight: 600,

        },
        '& .cardContent': {
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 4,
            padding: '4px 8px',
            '& h1': {
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 24,
                margin: 0,
                fontWeight: 500,
                lineHeight: '42px'
            }
        },
        '& .perform': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            '& span': {
                fontSize: 11,
                color: 'rgba(0, 0, 0, 0.5)',
            },
            '& p': {
                margin: 0,
                fontSize: 13,
                color: 'rgba(0, 0, 0, 0.9)',
            },
            '& .values': {
                textAlign: 'center'
            },
            '& .divider': {
                margin: '0px 2px'
            }
        }
    },
    cardHeader: {
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .titleHdr': {
            display: 'flex',
            alignItems: 'center',
            '& .rank': {
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 4,
                textAlign: 'center',
                padding: '2px 5px',
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: 13,
                marginRight: 12,
                '& span': {
                    marginLeft: '-3px'
                }
            },
            '& p': {
                margin: 0
            }
        },
        '& .ColorCode': {
            margin: 0,
            width: 18,
            height: 18,
            borderRadius: 2,
        }
    },
    skeletonRoot: {
        padding: '10px 0px',
        height: 135,
        marginBottom: 150,
        "& .card": {
            bordeRadius: 8,
            padding: '10px 8px',
            borderRadius: 4,
        },
    },
    filterHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
        '& .MuiInputBase-formControl input': {
            height: '17px !important'
        },
        '& label': {
            fontSize: '14px !important'
        }
    },
    autocompleteRoot: {
        borderRadius: 6,
        padding: '3px 2px !important',
        width: 120,
        '& label': {
            fontSize: '14px !important'
        }
    },
    dataFilters: {
        '& .MuiButton-outlinedPrimary': {
            border: '1px solid #cecece !important'
        }
    },
    dataFilterSelected: {
        background: theme.palette.primary.light
    },
    datCyclesDaates: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px 1px rgba(161, 161, 161, 0.25)',
        borderRadius: '8px',
    },
    dates: {
        padding: '0px 0px 8px',
        display: 'flex',
        justifyContent: 'end',
        '& span': {
            fontStyle: 'italic',
            fontSize: 12.5,
            color: '#8a8a8a'
        }
    }
}));

const MyPerformance = ({ filtersData, seasonId, userDetails, getPlayersPerformanc, seasonKpiPostions, type, seasonDetails, filterView, setFilterView }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [playerDetails, setPlayerDetails] = useState({});
    const [playerRank, setPlayerRank] = useState({});
    const [playerKpiStats, setPlayerKpiStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [bonusKpiStats, setBonusKpiStats] = useState([]);
    const [filterViewBy, setFilterViewBy] = useState(dataFrequencyFormat[0]);



    useEffect(() => {
        if (filtersData?.season?._id) {
            let seasonId = filtersData?.season?._id;
            getPlayersCorePoints(seasonId, 'CORE', filterViewBy);
            getPlayersBonusPoints(seasonId, 'BONUS', filterViewBy);
        }
    }, [filtersData?.season?._id, filterViewBy]);


    const getPlayersCorePoints = (seasonId, type, view) => {
        getPlayersPerformanc({
            params: { season_id: seasonId, type: type, cycle: view?.id ? (view?.id === 'SEASON' ? null : view.id) : null, cycle_id: seasonDetails?.current_cycles?.[view?.id?.toLowerCase()]?._id || null, limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result) {
                    const playerPoints = result[0];
                    if (playerPoints) {
                        setPlayerDetails({ name: playerPoints.name, username: playerPoints.username, logo_url: playerPoints.photo_url ? playerPoints.photo_url : '' });
                        setPlayerRank(playerPoints);
                        let kpis_attributes = playerPoints?.kpis // seasonKpiPositionsSort(playerPoints?.kpis, seasonKpiPostions, 'kpi_id');
                        setPlayerKpiStats(kpis_attributes);
                    }
                    else {
                        setPlayerDetails({});
                        setPlayerRank({});
                        setPlayerKpiStats([]);
                    }
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }
    const getPlayersBonusPoints = (seasonId, type, view) => {
        getPlayersPerformanc({
            params: { season_id: seasonId, type: type, cycle: view?.id ? (view.id === 'SEASON' ? null : view.id) : null, cycle_id: seasonDetails?.current_cycles?.[view?.id?.toLowerCase()]?._id || null, limit: 5000, page: 1 },
            onSuccessCB: (result, headers) => {
                if (result) {
                    const playerPoints = result[0];
                    if (playerPoints) {
                        let kpis_attributes = playerPoints?.kpis;
                        setBonusKpiStats(kpis_attributes);
                    }
                    else {
                        setBonusKpiStats([]);
                    }
                }
                setLoading(false);
            }, onErrorCB: (error) => {
                setLoading(false);
                enqueueSnackbar((error.response && error.response.data) ? error.response.data.error_description : 'Failed to fetch points', {
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        })
    }

    const skeletonLoading = (
        <div>
            {/* <Skeleton height={40} width="20%" /> */}
            <Grid container spacing={1} className={classes.skeletonRoot}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((ld, id) => (
                    <Grid key={id} item lg={3} md={6} sm={6} xs={12}>
                        <Card className="card">
                            <CardHeader style={{ padding: 0 }}
                                avatar={<Skeleton variant="reactangle" width={40} height={30} style={{ borderRadius: 4 }} />}
                                title={<Skeleton height={20} width="98%" />}
                            />
                            <CardContent style={{ padding: 0 }}>
                                <Skeleton height={20} width="100%" />
                                <Skeleton height={60} width="100%" />
                            </CardContent>
                        </Card>
                    </Grid>
                ))
                }
            </Grid >
        </div>
    );

    return (
        <div className={classes.root}>
            <div className={classes.filterHeader}>
                <div className={classes.heading}>
                    <h2>KPI Performance</h2>
                </div>
                {seasonDetails?.data_upload_type === 'MTD' &&
                    <div className={classes.dataFilters}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            {
                                dataFrequencyFormat.map((el) => (
                                    <Button
                                        key={el.id}
                                        color="primary"
                                        style={{ height: 31 }}
                                        className={filterViewBy?.id === el.id ? classes.dataFilterSelected : classes.dataFilterNonSelected}
                                        onClick={() => { setFilterViewBy(el); setFilterView(el) }}
                                    >
                                        {el.name}
                                    </Button>
                                ))
                            }
                        </ButtonGroup>
                        {/* <Autocomplete
                        id="filterViewBy"
                        options={dataFrequencyFormat}
                        getOptionLabel={(option) => option.name || ''}
                        value={filterViewBy}
                        disableClearable
                        classes={{ inputRoot: classes.autocompleteRoot }}
                        renderInput={(params) => <TextField {...params} size='small' variant="outlined" id="filterViewBy" label="Filter By" />}
                        onChange={(...args) => { setFilterViewBy(args[1]); setFilterView(args[1]) }}
                    /> */}
                    </div>
                }
            </div>
            <div className={classes.datCyclesDaates} style={(seasonDetails?.data_upload_type === 'MTD' && filterViewBy?.id !== 'SEASON') ? { padding: 8 } : { padding: 0 }}>
                {(seasonDetails?.data_upload_type === 'MTD' && filterViewBy?.id !== 'SEASON') &&
                    <div className={classes.dates}>
                        <span>Start Date :: {formatDate(seasonDetails?.current_cycles?.[filterView?.id?.toLowerCase()]?.start_date)}</span>
                        <span style={{ paddingLeft: 20 }}>End Date :: {formatDate(seasonDetails?.current_cycles?.[filterView?.id?.toLowerCase()]?.end_date)}</span>
                    </div>
                }
                {loading && skeletonLoading}
                {(playerKpiStats?.length > 0 || bonusKpiStats?.length > 0) ?
                    <MyperformanceCard playerKpiStats={playerKpiStats} bonusKpiStats={bonusKpiStats} loading={loading} filterViewBy={filterViewBy} />
                    :
                    !(loading && skeletonLoading) && <p style={{ textAlign: 'center', padding: '10px 8px', }}> No Kpi's Available... </p>
                }
            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.user,
    seasonKpiPostions: state.game.seasonKpiPostions,
    seasonDetails: state.game.seasonDetails,
    filterView: state.game.filterView
});

const mapDispatchToProps = dispatch => {
    return {
        setLoading: value => dispatch(setLoading(value)),
        getPlayersPerformanc: (options) => dispatch(getPlayersPerformanc(options)),
        setFilterView: (options) => dispatch(setFilterView(options))
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPerformance));